
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>会员综合bi平台</h3>
      <p>赋能会员全生命周期价值，驱动企业业务增长</p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="content">
      <h6>微心动会员综合bi平台</h6>
      <p class="title">
        搭建会员顾客的数据化管理、基础分析数据、会员的价值指标分析和会员的生命周期管理等综合bi平台，采用商业智能对企业进行专业的、规模的剖析，以数据为依托，软件为手段，根据不同的分析方法建立系统的、多元化的分析方案，为企业提供最具性价比的数据分析和信息化解决方案。
      </p>
      <div class="comprehensive">
        <div>
          <img src="../../assets/images/comprehensive01.png" />
          <h5>数据采集</h5>
          <p>全域会员的数据保存、展现和分析，赋能精细化运营管理。</p>
        </div>
        <div>
          <img src="../../assets/images/comprehensive02.png" />
          <h5>数据报表</h5>
          <p>
            同步数据并智能分析数据，支持多维度报表生成，直接读取数据报表，给领导提供决策。
          </p>
        </div>
        <div>
          <img src="../../assets/images/comprehensive03.png" />
          <h5>数据查询</h5>
          <p>支持多种数据查询方式，自定义个性化的查询模式。</p>
        </div>
        <div>
          <img src="../../assets/images/comprehensive04.png" />
          <h5>可视化展示</h5>
          <p>
            查询出来的数据，我们可以进行可视化的展现。比如用柱状图、折线图、饼图等各种形式来表示。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    onapply(){
        // this.childEvent(true);
        window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
      }
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/Banner_5.png);
  background-size: 100% 100%;
  text-align: center;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    font-size: 0.2rem;
    line-height: 0.8rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.content {
  padding: 0.98rem;
  h6 {
    text-align: center;
    font-size: 0.28rem;
    margin-bottom: 0.58rem;
  }
  .title {
    font-size: 0.18rem;
    color: #666;
    padding: 0 1.5rem;
    line-height: 0.24rem;
  }
  .comprehensive {
    padding: 0.3rem 1.78rem;
    display: flex;
    justify-content: space-between;
    div {
      width: 3.2rem;
      text-align: center;
      img {
        width: 0.68rem;
        height: 0.68rem;
      }
      h5 {
        padding: 0.1rem 0 0.16rem;
        font-size: 0.22rem;
      }
      p {
        font-size: 0.18rem;
        color: #666;
        line-height: 0.24rem;
      }
    }
  }
}
</style>
